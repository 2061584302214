import Vue from 'vue'

const item = {
    state: {
        items: []
    },

    getters: {
        items: state => state.items
    },

    mutations: {
        reset(state) {
            state.items = []
        }
    },

    actions: {
        async searchItems(context, { nxSaleId, stockNo }) {
            const res = await Vue.prototype.$api.get(`/nx/sales/${nxSaleId}/items`, {
                params: {
                    stock_no: stockNo || ''
                }
            })
            return res.data
        },

        async listItems({ state }, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/highlights/items`)
            state.items = res.data
            return state.items
        },

        async addItem({ state }, { saleId, itemId }) {
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/highlights/items/${itemId}`, {
                weight: 1
            })

            state.items.push(res.data)
            return res.data
        },

        async setStarItem({ state }, { saleId, itemId, isStar }) {
            await Vue.prototype.$api.put(`/sales/${saleId}/highlights/items/${itemId}/star`, {
                is_star: isStar
            })
            const high = state.items.find(high => high.item.id === itemId)
            if (high) {
                high.is_star = isStar
            }
        },

        async removeItem({ state }, { saleId, itemId }) {
            await Vue.prototype.$api.delete(`/sales/${saleId}/highlights/items/${itemId}`)
            const index = state.items.findIndex(highlight => highlight.item.id === itemId)
            if (index !== -1) {
                state.items.splice(index, 1)
            }
        },

        async removeItemByIndexes({ state, dispatch }, { saleId, indexes }) {
            const items = state.items.filter((_, i) => indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => dispatch('removeItem', { saleId, itemId: item.item.id })))
        },

        async sortItems(context, { saleId, itemIds }) {
            return Vue.prototype.$api.post(`/sales/${saleId}/highlights/items-sorts`, {
                item_ids: itemIds
            })
        }
    }
}

const lot = {
    state: {
        lots: []
    },

    getters: {
        lots: state => state.lots
    },

    mutations: {},

    actions: {
        async searchLots(context, { saleId, vacationId, lotNb }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/lots`, {
                params: {
                    mode: 'db',
                    lot_nb: lotNb,
                    vacation_ids: [vacationId]
                }
            })
            return res.data
        },

        async listLots({ state }, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/highlights/lots`)
            state.lots = res.data
            return state.lots
        },

        async addLot({ state }, { saleId, lotId }) {
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/highlights/lots/${lotId}`, {
                weight: 1
            })
            state.lots.push(res.data)
            return res.data
        },

        async setStarLot({ state }, { saleId, lotId, isStar }) {
            await Vue.prototype.$api.put(`/sales/${saleId}/highlights/lots/${lotId}/star`, {
                is_star: isStar
            })
            const high = state.lots.find(high => high.lot.id === lotId)
            if (high) {
                high.is_star = isStar
            }
        },

        async removeLot({ state }, { saleId, lotId }) {
            await Vue.prototype.$api.delete(`/sales/${saleId}/highlights/lots/${lotId}`)
            const index = state.lots.findIndex(highlight => highlight.lot.id === lotId)
            if (index !== -1) {
                state.lots.splice(index, 1)
            }
        },

        async removeLotByIndexes({ state, dispatch }, { saleId, indexes }) {
            const items = state.lots.filter((_, i) => indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => dispatch('removeLot', { saleId, lotId: item.lot.id })))
        },

        async sortLots(context, { saleId, lotIds }) {
            return Vue.prototype.$api.post(`/sales/${saleId}/highlights/lots-sorts`, {
                lot_ids: lotIds
            })
        }
    }
}

export default {
    namespaced: true,

    state: {
        ...item.state,
        ...lot.state
    },

    getters: {
        ...item.getters,
        ...lot.getters
    },

    mutations: {
        ...item.mutations,
        ...lot.mutations
    },

    actions: {
        ...item.actions,
        ...lot.actions
    }
}
