<template>
    <v-list dense class="pt-0">
        <hdv-sale-current />

        <hdv-sale-list-selector v-model="selectedId" @selected="select" include-nx return-object />

        <template v-if="selected.id">
            <v-alert tile dark dense small :color="selected.imported ? 'success' : 'warning'">
                <v-row>
                    <v-col class="grow">{{ selected.imported ? $t('sale.importedYes') : $t('sale.importedNo') }}</v-col>
                    <v-col v-if="selected.imported" class="shrink mr-1">
                        <hdv-sale-current-btn :sale-id="selected.id" :title="selected.title" />
                    </v-col>
                </v-row>
            </v-alert>
        </template>

        <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index" />
            <v-list-item v-else
                exact
                :key="item.text"
                :to="item.to"
                :disabled="disabled || !selected || (!selected.imported && !item.nxable)"
                @click="(item.click && item.click(item)) || null">
                <v-list-item-content>
                    <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small>{{ item.icon }}</v-icon>
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import HdvSaleListSelector from '@/components/sale-list/HdvSaleListSelector'
import HdvSaleCurrent from '@/components/sale/HdvSaleCurrent'
import HdvSaleCurrentBtn from '@/components/sale/HdvSaleCurrentBtn'

export default {
    name: 'hdv-sale-main-menu',
    components: { HdvSaleListSelector, HdvSaleCurrent, HdvSaleCurrentBtn },
    props: {
        disabled: { type: Boolean }
    },
    data: () => ({
        sale: null,
        defaultValue: null
    }),
    computed: {
        items() {
            const params = { saleId: this.selected.id || 0 }
            const saleForm = this.selected?.imported ? 'saleform' : 'saleadd'
            return [
                { to: { name: saleForm, params }, icon: '$vuetify.icons.saleInfo', text: 'sale.tabinfo', nxable: true },
                { to: { name: 'salespecial', params }, icon: '$vuetify.icons.saleSpecial', text: 'salespecial.tab' },
                { to: { name: 'salelot', params }, icon: '$vuetify.icons.saleLots', text: 'sale.tablots' },
                { to: { name: 'salehighlight', params }, icon: '$vuetify.icons.saleHighlight', text: 'sale.tabhighlight' },
                { to: { name: 'saleam', params }, icon: '$vuetify.icons.saleAm', text: 'saleam.tab' },
                { to: { name: 'saleseo', params }, icon: '$vuetify.icons.seo', text: 'sale.seo' }
            ]
        },

        selected() {
            return this.$store.getters['sale/selected'] || {}
        },

        selectedId: {
            get() {
                return this.$store.getters['sale/selectedId']
            },
            set(sale) {
                if (!sale?.id) {
                    return
                }
                const old = this.selected
                this.$store.commit('sale/select', { id: sale.id, sale })
                if (old && old !== sale.id) {
                    this.$router.push({ name: 'salehome' })
                }
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.query.imported) {
                this.selectedId = this.$route.params.saleId
            }
        }
    },
    mounted() {
        if (this.$route.params.saleId) {
            this.selectedId = this.$route.params.saleId
        }
    },
    methods: {
        select(sale) {
            this.$store.commit('sale/select', { id: sale.id, sale })
        }
    }
}
</script>
