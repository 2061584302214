import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import lodash from 'lodash'
import vuetifyconfig from '@/shared/plugins/vuetify'

Vue.use(Vuetify)

export default new Vuetify(lodash.merge(vuetifyconfig, {
    theme: {
        themes: {
            light: {
                primary: '#444',
                secondary: '#999',
                accent: '#CCC',
                menuActive: '#9f9'
            }
        }
    },
    icons: {
        values: {
            // base
            baseDuplicate: 'mdi-content-copy',
            baseSort: 'mdi-drag-horizontal-variant',
            baseToDetail: 'mdi-information',
            menuSecondaryOpen: 'mdi-plus',
            menuSecondaryClose: 'mdi-minus',
            menuWebsite: 'mdi-web',
            disabledState: 'mdi-circle-medium',
            // user
            passwordOn: 'mdi-eye',
            passwordOff: 'mdi-eye-off',
            logout: 'mdi-logout',
            // event
            event: 'mdi-calendar',
            // contents
            component: 'mdi-file-document-edit-outline',
            page: 'mdi-file-tree-outline',
            faq: 'mdi-frequently-asked-questions',
            story: 'mdi-video-image',
            department: 'mdi-diamond-stone',
            contentCategory: 'mdi-group',
            // sales
            sale: 'mdi-gavel',
            saleInfo: 'mdi-pencil',
            saleAm: 'mdi-ghost',
            saleLots: 'mdi-database-outline',
            saleSpecial: 'mdi-folder-star-outline',
            saleHighlight: 'mdi-star',
            importSale: 'mdi-web',
            importAm: 'mdi-ghost',
            importAll: 'mdi-set-all',
            importImgYes: 'mdi-image',
            importImgNo: 'mdi-image-off',
            saleCurrent: 'mdi-target',
            // catalog
            saleCatalog: 'mdi-image-multiple',
            saleCatalogPdf: 'mdi-file-image',
            // teams
            team: 'mdi-account-multiple',
            // seo
            seo: 'mdi-search-web',
            // queue
            queue: 'mdi-timer-sand',
            queue_image_transform: 'mdi-image-outline',
            queue_image_transform_normal: 'mdi-image-outline',
            queue_pdf_resize: 'mdi-file-pdf-box',
            queue_am_pushLots: 'mdi-ghost'
        }
    }
}))
