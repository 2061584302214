<template>
    <v-switch
        v-model="type"
        :label="$t('sale.highlightsLots')" />
</template>

<script>

export default {
    name: 'hdv-sale-highlights-lots',
    props: {
        value: { type: Boolean }
    },
    computed: {
        type: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
