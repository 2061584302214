<template>
    <v-card flat :loading="loading">
        <v-card-text>
            <p>{{ $t('salehighlight.textLot') }}</p>

            <hdv-sale-highlight-lot-search
                :sale-id="saleId"
                :disabled="disabled"
                v-model="highlight"
                @input="add" />

        </v-card-text>
        <v-list three-line class="pt-0" :disabled="loading">
            <hdv-base-actionable-list
                v-model="action"
                :total="highlights.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('salehighlight.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>

                <template #search>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">
                <draggable
                    :list="highlights"
                    :disabled="!sorting || loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <div v-for="highlight in highlights" :key="highlight.lot.id">
                        <v-divider />
                        <hdv-sale-highlight-list-item
                            :action="action"
                            :sorting="sorting"
                            :highlight="highlight"
                            :lot="highlight.lot"
                            :item="highlight.lot.item"
                            @star:change="toggleStar" />
                    </div>
                </draggable>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import HdvSaleHighlightListItem from '@/components/sale-highlight/HdvSaleHighlightListItem'
import HdvSaleHighlightLotSearch from '@/components/sale-highlight/HdvSaleHighlightLotSearch'
import draggable from 'vuedraggable'

export default {
    name: 'hdv-sale-highlight-lots',
    components: {
        HdvBaseActionableList,
        HdvSaleHighlightListItem,
        HdvSaleHighlightLotSearch,
        draggable
    },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        sorting: false,
        show: false,
        editItem: null,
        highlight: null,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        highlights() {
            return this.$store.getters['high/lots']
        }
    },
    watch: {
        saleId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('high/listLots', { saleId: this.saleId })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        add(lot) {
            if (!lot) {
                return
            }
            this.loading = true
            return this.$store.dispatch('high/addLot', { lotId: lot.id, saleId: this.saleId })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            if (this.sorting) {
                return
            }
            this.loading = true
            return this.$store.dispatch('high/removeLotByIndexes', { saleId: this.saleId, indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'salehighlight.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            this.loading = true
            return this.$store
                .dispatch('high/sortLots', {
                    saleId: this.saleId,
                    lotIds: this.highlights.map(high => high.id)
                })
                .then(res => {
                    this.sorting = false
                    return this.$success(res, { message: this.$i18n.t('salehighlight.sortSaved') })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        toggleStar({ highlight, isStar }) {
            this.loading = true
            return this.$store
                .dispatch('high/setStarLot', {
                    saleId: this.saleId,
                    lotId: highlight.lot.id,
                    isStar
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
