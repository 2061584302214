<template>
    <v-alert text color="info">
        <v-row align="center" no-gutters>
            <v-col class="grow">
                <h3 class="text-h5">{{ $t('salenx.title') }}: {{ title }}</h3>
                <p v-if="!id">{{ $t('salenx.import') }}</p>
                <p v-else v-marked="$t('salenx.imported')"></p>
            </v-col>
            <v-col class="shrink">
                <v-btn
                    outlined
                    rounded
                    color="info"
                    @click="importFromNx"
                    :loading="loading">
                    {{ $t('salenx.btn') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    name: 'hdv-sale-nx-import',
    props: {
        nxId: { type: [String, Number] },
        id: { type: String },
        title: { type: String }
    },
    data: () => ({
        loading: false
    }),
    methods: {
        importFromNx() {
            this.loading = true
            return this.$store.dispatch('sale/import', { id: this.id, nxId: this.nxId })
                .then(async sale => {
                    await this.$store.dispatch('sale/importStories', { id: sale.id, nxId: this.nxId })
                    if (!this.id) {
                        // on redirige sur la page d'édition de la vente web
                        this.$router.replace({ name: 'saleform', params: { saleId: sale.id }, query: { imported: true } })
                    }
                    this.$success(sale, { message: 'salenx.btnImported' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
